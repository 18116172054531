'use client';

import { useEffect, useState, useMemo, memo, Fragment } from 'react';
import Image from 'next/image';
import {
  CellContext,
  FilterFn,
  Row,
  SortingFn,
  SortingFnOption,
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getFilteredRowModel,
} from '@tanstack/react-table';
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils';
import { ChevronDownIcon, ChevronUpIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { LinkIcon } from '@heroicons/react/24/outline';
import { cn, numberFormatter } from '../../utils/helpers';
import BigNumber from 'bignumber.js';
import { TableColumn, TableData } from '../../types';
import TableSkeleton from '../skeletons/TableSkeleton';
import emptyChart from '../../../public/images/chart-builder-empty.svg';

declare module '@tanstack/table-core' {
  interface SortingFns {
    bigNumber: SortingFn<unknown>;
  }
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

type TableProps = {
  data: TableData;
  loading: boolean;
  visible?: boolean;
  searchable?: boolean;
  searchValue?: string;
  isInteractive?: boolean;
  headerCss?: string;
  border?: boolean;
  totals?: (string | number)[];
  expandable?: boolean;
  onScroll?: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  onRowClick?: (row: Row<CellData>) => void;
  renderSubComponent?: (row: Row<CellData>) => React.ReactNode;
};

export type CellData = { [key: string]: any };

export function isTableData(data: any): data is TableData {
  return data !== undefined && data.rows !== undefined && data.columns !== undefined;
}

export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const Table = ({
  data,
  loading,
  searchable,
  searchValue,
  visible = true,
  headerCss,
  border,
  totals,
  expandable,
  onRowClick,
  onScroll,
  renderSubComponent,
}: TableProps) => {
  if (!visible) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center">
        <Image src={emptyChart} alt="empty" className="w-48" />
        <span className="gap-2 text-xl font-medium">Select an event to get started</span>
      </div>
    );
  }

  if (!loading && !isTableData(data)) return null;

  return (
    <>
      {loading ? (
        <TableSkeleton />
      ) : (
        <TableComponent
          data={data}
          searchable={searchable}
          searchValue={searchValue}
          headerCss={headerCss}
          border={border}
          totals={totals}
          expandable={expandable}
          onScroll={onScroll}
          onRowClick={onRowClick}
          renderSubComponent={renderSubComponent}
        />
      )}
    </>
  );
};

export default Table;

type TableComponentProps = {
  data: TableData;
  headerCss?: string;
  searchable?: boolean;
  searchValue?: string;
  border?: boolean;
  totals?: (string | number)[];
  expandable?: boolean;
  onScroll?: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  onRowClick?: (row: Row<CellData>) => void;
  renderSubComponent?: (row: Row<CellData>) => React.ReactNode;
};

const TableComponent = ({
  data,
  headerCss,
  searchable,
  searchValue,
  border,
  totals,
  expandable,
  onRowClick,
  onScroll,
  renderSubComponent,
}: TableComponentProps) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState('');

  const columnHelper = createColumnHelper<CellData>();

  const columns = useMemo(
    () => [
      ...(expandable
        ? [
            columnHelper.display({
              id: 'expander',
              cell: ({ row }) => (
                <button
                  {...{
                    onClick: row.getToggleExpandedHandler(),
                    style: { cursor: 'pointer' },
                  }}>
                  {row.getIsExpanded() ? <ChevronDownIcon className="h-5 w-5" /> : <ChevronRightIcon className="h-5 w-5" />}
                </button>
              ),
              header: () => null,
              enableSorting: false,
            }),
          ]
        : []),
      ...data.columns.map((column: TableColumn) => {
        const accessor = columnHelper.accessor(column.accessorKey || column.name, {
          header: () => column.name,
          cell: (content) =>
            column.formatter ? (
              <div className="text-sm text-brand-text-base">{column.formatter(content.row.original)}</div>
            ) : (
              constructCell(content, column)
            ),
          enableSorting: column.enableSorting,
          sortingFn: (column.sortingFn as SortingFnOption<CellData>) ?? 'auto',
        });

        return accessor;
      }),
    ],
    [data.columns, expandable],
  );

  const columnWidthClass = `w-1/${columns.length}`;

  useEffect(() => {
    if (searchValue) {
      setGlobalFilter(String(searchValue));
    } else {
      setGlobalFilter('');
    }
  }, [searchValue]);

  const table = useReactTable({
    data: data.rows,
    columns,
    state: {
      sorting,
      globalFilter,
    },
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    sortingFns: {
      bigNumber: bigNumberSort,
    },
    enableGlobalFilter: searchable,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getRowCanExpand: () => expandable ?? false,
  });

  const isFooter = totals && totals?.length > 0;

  return (
    <div
      className={cn(
        'h-full overflow-x-scroll overflow-y-scroll px-4 sm:px-6 lg:px-8',
        border && 'rounded-lg shadow ring-1 ring-black ring-opacity-5',
      )}
      onScroll={onScroll}>
      <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle">
          <table className="min-w-full border-separate border-spacing-0">
            <thead className={headerCss}>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header, idx) => (
                    <th
                      key={header.id}
                      scope="col"
                      onClick={header.column.getToggleSortingHandler()}
                      className={cn(
                        'sticky top-0 cursor-default items-center border-b border-gray-300 bg-white bg-opacity-50 p-4 text-left text-sm font-semibold text-gray-900 backdrop-blur',
                        data?.columns?.[idx]?.columnHeaderCss,
                      )}>
                      <div className="group inline-flex max-w-48">
                        <span className="truncate">
                          {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                        </span>
                        {header.column.getIsSorted() === 'asc' && <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />}
                        {header.column.getIsSorted() === 'desc' && <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <Fragment key={row.id}>
                  <tr
                    key={row.id}
                    className={onRowClick ? 'hover:bg-gray-200' : ''}
                    onClick={() => {
                      if (onRowClick) onRowClick(row);
                    }}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className={cn(
                          index !== data.rows.length - 1 ? 'border-b border-gray-100' : '',
                          `text-ellipsis whitespace-nowrap p-4 text-left ${columnWidthClass}`,
                        )}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                  {expandable && row.getIsExpanded() && (
                    <tr>
                      <td className="p-0" colSpan={row.getVisibleCells().length}>
                        {renderSubComponent ? renderSubComponent(row) : null}
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
            </tbody>
            {isFooter && (
              <tfoot>
                <tr>
                  {totals.map((total, index) => (
                    <td
                      key={index}
                      className={cn(
                        'sticky bottom-0 z-10 cursor-default items-center border-t border-gray-300 bg-white bg-opacity-50 p-4 text-left text-sm font-semibold text-gray-900 backdrop-blur',
                      )}>
                      {total}
                    </td>
                  ))}
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

function constructCell(content: CellContext<CellData, string>, column: TableColumn) {
  const href = column.linkConfig ? content.row.original[column.linkConfig.key] : undefined;

  let formattedText = column.valueFormat ? numberFormatter(content.getValue(), column.valueFormat) : content.getValue();

  const cellContent = (
    <div className="max-w-80 overflow-hidden text-ellipsis text-sm text-brand-text-base hover:max-w-none">
      {href ? (
        <a
          href={href}
          target={column.linkConfig!.newTab === false ? undefined : '_blank'}
          rel="noreferrer"
          className="group flex items-center gap-1 transition-colors hover:text-gray-400 hover:underline">
          {formattedText}
          <LinkIcon className="h-4 w-4 text-gray-500 transition-colors group-hover:text-gray-400" aria-hidden="true" />
        </a>
      ) : column.valueFormat ? (
        numberFormatter(content.getValue(), column.valueFormat)
      ) : (
        content.getValue()
      )}
    </div>
  );

  return cellContent;
}

export const bigNumberSort = (rowA: Row<CellData>, rowB: Row<CellData>, columnId: string): number => {
  const valueA = BigNumber(rowA.getValue(columnId));
  const valueB = BigNumber(rowB.getValue(columnId));
  if (valueA.isLessThan(valueB)) {
    return 1;
  } else {
    return -1;
  }
};
