import { BigNumber } from 'ethers';
import { StackOffsetType } from 'recharts/types/util/types';
import { ReactNode } from 'react';

export type Organization = {
  id: string;
  name: string;
};

export const isOrganization = (obj: any): obj is Organization => {
  return obj && obj.id && obj.name;
};

export type NavigationItem = {
  name: string;
  href: string;
  icon: React.ElementType;
  current: boolean;
};

export type DashboardTab = {
  name: string;
  type: string;
  current: boolean;
  editable: boolean;
  hideable: boolean;
  visible?: string;
};

export type DashboardLayout = {
  dateFilter: DashboardDateRange;
  sections: TDashboardSection[];
};

export type TDashboardSection = {
  id: string;
  rows: TDashboardRow[];
  header?: Header;
};

export type TDashboardRow = {
  id: string;
  header: Header;
  columns: DashboardColumn[];
};

export type DashboardColumn =
  | {
      type: ChartType.KPI;
      title: string;
      uuid: string;
      id: string;
    }
  | {
      type: ChartType.FUNNEL_CHART;
      title: string;
      uuid: string;
      subtitle?: string;
      id: string;
    }
  | {
      type: ChartType.SANKEY_CHART;
      title: string;
      uuid: string;
      subtitle?: string;
      id: string;
    }
  | {
      type: ChartType.COHORT_ANALYSIS;
      title: string;
      uuid: string;
      subtitle?: string;
      id: string;
    }
  | {
      type: ChartType.BAR_CHART;
      title: string;
      uuid: string;
      id: string;
    }
  | {
      type: ChartType.LINE_CHART;
      title: string;
      uuid: string;
      id: string;
    }
  | {
      type: ChartType.BAR_LIST;
      title: string;
      uuid: string;
      id: string;
    }
  | {
      type: ChartType.TABLE;
      title: string;
      uuid: string;
      id: string;
      aggregate: boolean;
    };

export enum DashboardType {
  OVERVIEW = 'overview',
  RETENTION = 'retention',
  CAMPAIGNS = 'campaigns',
  ECO = 'eco',
  QUESTS = 'quests',
  ANALYTICS = 'analytics',
  REFERRAL_PROGRAM = 'referral-program',
  REFERRAL_CODE = 'referral-code',
  REFERRAL_CAMPAIGN = 'referral-campaign',
  AUDIENCE_INTELLIGENCE = 'audience-intelligence',
}

export type Header = {
  title: string;
  filters: Filter[];
};

export enum FilterType {
  SELECT = 'select',
  MULTISELECT = 'multiselect',
}

// This is the type for a UI filter
export type Filter = {
  type: string;
  id: string;
  options: {
    value: string;
    label: string;
    default?: boolean;
  }[];
};

export type ActiveFilter = {
  id: string;
  value: any;
};

export enum ChartType {
  KPI = 'number',
  FUNNEL_CHART = 'funnel_chart',
  SANKEY_CHART = 'sankey_chart',
  COHORT_ANALYSIS = 'cohort_analysis',
  BAR_CHART = 'bar_chart',
  LINE_CHART = 'line_chart',
  BAR_LIST = 'bar_list',
  TABLE = 'table',
  PIE_CHART = 'pie_chart',
}

type DashboardDateRange = {
  defaultStartDate: string;
  defaultEndDate: string;
};

export type DateRange = { from: string; to: string; selectValue?: string };

export type ChartCardProps = {
  rowCount: number;
  rowIndex: number;
  title: string;
  uuid: string;
  chartType: ChartType;
  subtitle?: string;
  dateRange: DateRange;
  editMode: boolean;
  sectionFilters?: ActiveFilter[];
  dashboardType: string;
  campaignId?: string;
  admin?: boolean;
  editable?: boolean;
  onDelete: (uuid: string) => void;
};

export type KPICardWithGraphData = {
  x: string;
  y: string[];
  value: string;
  valueFormat?: string;
  values: GraphData[];
};

export type ChartData = {
  values: DateAndFields[];
  x: string;
  y: string[];
  period?: string;
  labelFormat?: string;
  yLabel: string;
  displayValue?: string;
  valueFormat?: string;
  stacked?: boolean;
  showLegendTotal?: boolean;
  hideLegend?: boolean;
  removeZeroValues?: boolean;
  connectNulls?: boolean;
  stackOffset?: StackOffsetType;
};

type DateAndFields = {
  Date: string;
} & { [key: string]: string | number };

export type KPIData = {
  data: string;
  valueFormat?: string;
};

export type PieChartData = {
  event: string;
  values: { name: string; value: number; color?: string }[];
  valueFormat?: string;
};

export type TableData = { rows: any[]; columns: TableColumn[]; aggregate?: boolean };

export type TableColumn = {
  name: string;
  valueFormat?: string;
  columnHeaderCss?: string;
  enableSorting?: boolean;
  accessorKey?: string;
  sortingFn?: 'alphanumeric' | 'alphanumericCaseSensitive' | 'auto' | 'text' | 'textCaseSensitive' | 'datetime' | 'bigNumber' | 'basic';
  linkConfig?: {
    key: string;
    newTab?: boolean;
  };
  formatter?: (row: any) => JSX.Element | null | string;
};

export type GraphData = {
  date: string;
  Date?: string;
  amount: string;
};

export type BarListData = {
  name: string;
  values: {
    [key: string]: number;
  };
};

export enum LabelFormat {
  CAPITALIZE = 'CAPITALIZE',
  NONE = 'NONE',
}

export enum LegendLayout {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export type Step = {
  name: string;
  status: string;
};

export type CohortAnalysisData = {
  headers: string[];
  cohorts: Cohort[];
};

export type SankeyData = {
  nodes: {
    id: string;
    name: string;
    segment?: string;
  }[];
  links: { source: string; target: string; value: number }[];
  direction: SankeyDirection;
};

export type Cohort = {
  name: string;
  size: string;
  periods: Period[];
};

export type Period = {
  period: string;
  retention: string;
};

export type ABIMethod = {
  value: string;
  label: string;
  enabled: boolean;
};

export type ContractFormInputs = {
  blockchain: string;
  contractAddress: string;
  contractName: string;
};

export type OnchainCampaignFormInputs = {
  campaignName: string;
  deployedContract: {
    value: {
      id: string;
      address: string;
      chainId: ChainId;
    };
    label: string | undefined;
  };

  paymentType: { value: string; label: string | undefined };
  actions: FormActionInput[];
};

export type FormActionInput = {
  action: { value: string; label: string | undefined };
  payoutType: { value: string; label: string | undefined };
  referrerPayoutValue: string;
  referentPayoutValue: string;
};

export type ReferrerFormInputs = {
  address: string;
  slug: string;
};

export type AddFundsFormInputs = {
  amount: string;
};

export type TransferFundsFormInputs = {
  amount: string;
  subCampaignId: {
    value: string;
    label: string;
  };
};

export type TopUpFundsFormInputs = {
  amount: string;
};

export type WithdrawFundsFormInputs = {
  amount: string;
  toAddress: string;
};

export type TransferFundsData = {
  success: boolean;
  erc20ApproveTx?: string;
  fundCampaignTx?: string;
};

export type WithdrawFundsData = {
  success: boolean;
  withdrawalTx?: string;
};

export enum ChainId {
  ETHEREUM = 1,
  OPTIMISM = 10,
  POLYGON = 137,
  ARBITRUM = 42161,
  ETH_SEPOLIA = 11155111,
  BSC = 56,
  BSC_TESTNET = 97,
  BASE = 8453,
  BASE_SEPOLIA = 84532,
  ARB_SEPOLIA = 421614,
  ZKSYNC = 324,
}

export enum ContractType {
  ERC20 = 'ERC20',
  ERC721 = 'ERC721',
  ERC1155 = 'ERC1155',
}

export type Contract = {
  id?: string;
  name: string;
  address: string;
  type: ContractType[];
  chainId: number;
  abi: string;
  conversionConfig: ConversionConfig[];
  logConfig: LogConfig[];
};

export type ActionConfig = {
  id: string;
  org_id: string;
  name: string;
  description: string;
  query: string;
  active: boolean;
  referral_campaign_id: string;
  created_at: Date;
  updated_at: Date;
};

export type ActionConfigsForOnchainV2 = {
  action_configuration_id: string;
  active: boolean;
  created_at: string;
  updated_at: string;
  reward_type: string;
  percentage_based_field_name: string;
  org_id: string;
  name: string;
  referer_value: number;
  referent_value: number;
  referral_campaign_id: string;
  reward_currency: string;
  referral_campaign_tier_description: string | null;
  referral_campaign_tier_id: string;
  referral_campaign_tier_name: string;
  referral_subcampaign_id: string;
  reward_cadence: string;
  reward_chain_id: string;
  reward_tracking_config_id: string;
  decimals: number;
  num_days: number | null;
  tracking_config_type: 'unbounded' | 'time_bounded' | null; // unbounded
};

export type ConversionConfig = {
  methodId: string;
  name: string;
  isConversion: boolean;
};

export type LogConfig = {
  logID: string;
  name: string;
};

export type ContractGetSourceCode = {
  status?: string;
  message?: string;
  result?: ContractSourceCodeResult[];
};

export type ContractSourceCodeResult = {
  SourceCode: string;
  ABI: string;
  ContractName: string;
  CompilerVersion: string;
  OptimizationUsed: string;
  Runs: string;
  ConstructorArguments: string;
  EVMVersion: string;
  Library: string;
  LicenseType: string;
  Proxy: string;
  Implementation: string;
  SwarmSource: string;
};

export enum OnchainCampaignStatus {
  NONE = 0,
  CREATED = 1,
  ACTIVE = 2,
  PAUSED = 3,
  COMPLETED = 4,
}

export enum OnchainReferrerStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
}

export type ValueFormatter = {
  (value: number): string;
};

export type Campaign = {
  id: string;
  org_id: string;
  name: string;
  referral_campaign_id: string;
  payment_type: string;
  created_at: string;
  description: string;
  end_date: string;
  start_date: string;
  status: string;
  targeting_rules: TargetingRule[];
  total_budget: number;
  daily_budget: number;
  updated_at: string;

  actionConfigurations: ActionConfigsForOnchainV2[];
  subCampaigns: SubCampaign[];
  dbTotalEarned: string; // distinguishing from total earned from smart contract
};

export type InvoiceLineItem = {
  name: string;
  referer_code: string;
  address: string;
  amount: string;
  tx_hash: string;
};

export type Invoice = {
  invoice_id: string;
  invoice_date: string;
  status: string;
  created_at: string;
  total_amount: number;
  name: string;
  currency: string;
  tx_hash: string;
  chain_id: number;
  line_items: InvoiceLineItem[];
};

export type CampaignMetadata = {
  id: string;
  name: string;
  status: CampaignStatus;
  created_at: string;
  total_budget: number;
  daily_budget: number;
  subCampaigns?: {
    id: string;
    payout_currency_type: string | null;
    payout_currency_name: string | null;
  }[];
};

export enum CampaignStatus {
  ADS_ONLY = 'ADS_ONLY', // Deprecated
  PENDING = 'PENDING', // Deprecated -> IN_REVIEW
  IN_REVIEW = 'IN_REVIEW', // Initial State
  DEPLOYING = 'DEPLOYING', // Smart Contract Creation and Deployment
  PROCESSED = 'PROCESSED', // Deprecated -> LIVE
  LIVE = 'LIVE',
  PAUSED = 'PAUSED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

// this was based on databricks version of schema
export type SubCampaign = {
  id: string;
  org_id: string;
  name: string;
  description: string;
  contract_campaign_id: number;
  referral_campaign_id: string;
  deployed_contract_address: string;
  payout_currency_type: string;
  payout_currency_name: string;
  decimals: number;
  chain_id: string;
  chain_name: string;
  total_earned: number;
  created_at: Date;
  updated_at: Date;
};

export type TargetingRule = {
  type: string;
  op: FilterOperation;
  values: {
    id: string;
    name: string;
    num_addresses?: number;
  }[];
};

export type TargetingConfig = {
  key: string;
  op: FilterOperation;
  values: string[];
};

// this is the schema for the prisma subCampaigns table
export type PrismaSubCampaign = {
  id: string;
  orgId: string;
  advertiserId?: string;
  referralCampaignId: string;
  name: string;
  description?: string;
  chainId?: bigint;
  chainName?: string;
  payoutCurrencyType?: string;
  payoutCurrencyName?: string;
  decimals?: number;
  deployedContractAddress?: string;
  contractCampaignId?: number;
  createdAt: Date;
  updatedAt: Date;
};

export type SubCampaignWithOnchainData = SubCampaign & { total_budget: any; status: any; total_paid_out: any };

export enum PayoutType {
  CPA = 'CPA',
  CPV = 'CPV',

  // new payout types
  CPV_ONE_TIME = 'cpv_one_time',
  CPA_ONE_TIME = 'cpa_one_time',
  CPA_RECURRING = 'cpa_recurring',
  CPV_RECURRING = 'cpv_recurring',
  FLAT = 'flat',
  PERCENTAGE = 'percentage',
}

export type ReferrerCode = {
  id: string;
  orgId: string;
  slug: string;
  address: string;
  createdAt: string;
};

export type ProxyContract = {
  id: string;
  orgId: string;
  status: string;
  address: string;
  chainId: number;
  indexerUrl: string;
  abiVersion: string;
  abi: string;
  createdAt: string;
  updatedAt: string;
};

export type OptionType = {
  label: string | ReactNode;
  value: any;
  icons?: ReactNode[];
};

export type GroupedOptionType = {
  label: string | ReactNode;
  options: OptionType[];
};

export type Recipient = {
  id: string;
  address: string;
  status?: RecipientStatus;
  earned?: string;
  withdrawn?: string;
};

export enum RecipientStatus {
  NONE = 0,
  ACTIVE = 1,
  PAUSED = 2,
}

export type ReciepientIndexResponse = {
  updateCompleteds: UpdateCompleteds[];
  withdrawSuccesses: WithdrawSuccesses[];
  recipientStatusUpdateds: RecipientStatusUpdateds[];
};

export type UpdateCompleteds = {
  newEarnings: string;
  recipientAddress: string;
  timestamp_: string;
};

export type WithdrawSuccesses = {
  totalWithdrawn: string;
  recipientAddress: string;
  timestamp_: string;
};

export type RecipientStatusUpdateds = {
  status: number;
  addr: string;
};

export type OnchainPaymentOptsIdxResponse = {
  paymentAddeds: {
    paymentAddress: string;
  }[];
};

export type CampaignReward = {
  attr_attribution_channel: string;
  event_name: string;

  referer_total_earned: number;
  referent_total_earned: number;
  event_count: number;
};

export enum MembershipRole {
  NONE = 'NONE',
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export type OnchainCampaignResp = {
  paymentType: string;
  status: number;
  totalBudget: BigNumber;
  totalEarned: BigNumber;
};

export enum CampaignCreationStatus {
  PENDING = 'PENDING',
  PROCESSED = 'PROCESSED',
}

export type OnchainCampaignRow = {
  id: string;
  createdAt: string;
  status: number;
  paymentType: string;
};

export enum EventMeasure {
  count = 'count',
  unique_users = 'unique_users',
  unique_wallets = 'unique_wallets',
}

export type EventLogFilterInputs = {
  eventType: UnifiedEventType[];
};

export interface ILocation {
  city: string;
  region: string;
  country: string;
}

interface ILink {
  id: string;
  name: string;
  link: string;
}

interface IDevice {
  platform: string | null;
  os: string | null;
  osVersion: string | null;
  browser: string | null;
  browserVersion: string | null;
}

interface IWallet {
  nfts: number;
  usdValue: string;
  ens: string | null;
  firstTransaction: string;
}

interface IReferrer {
  utmMedium: string;
  utmSource: string;
  utmCampaign: string;
  utmContent: string;
  utmTerm: string;
  source: string;
  ref: string;
  referer: string;
}

export interface IConversionAction {
  action: string;
  txHash: string;
  chainId: string;
  data: any;
}

export interface IPage {
  url: string;
  pathname: string;
  params: any;
}

export interface IPublicClickLog {
  id: string;
  event: EventLogName.link_redirect;
  identity: string;
  timestamp: string;
  link: ILink | null;
  location: ILocation | null;
  spindlIdentity: string | null;
  device: IDevice | null;
  referrer: IReferrer;
}

export interface IPublicPageViewLog {
  id: string;
  event: EventLogName.page_view;
  identity: string;
  restrictedHash: string;
  timestamp: string;
  location: ILocation | null;
  device: IDevice | null;
  referrer: IReferrer;
  spindlIdentity: string | null;
  page: IPage;
  screenSize: string | null;
  clientTs: string | null;
  sdkType: string | null;
  sequenceId: number | null;
  isProxyReq: boolean | null;
}

export interface IPublicMatchLog {
  id: string;
  event: EventLogName.wallet_connect;
  timestamp: string;
  identity: string;
  restrictedHash: string;
  walletAddress: string;
  location: ILocation | null;
  device: IDevice | null;
  referrer: IReferrer;
  walletProfile: IWallet | null;
  spindlIdentity: string | null;
  page: IPage;
  screenSize: string | null;
  clientTs: string | null;
  sdkType: string | null;
  sequenceId: number | null;
  isProxyReq: boolean | null;
}

export interface ICustomEvent {
  id: string;
  event: EventLogName.custom;
  address: string | null;
  name: string;
  eventTypeId: string;
  properties: any;
  timestamp: string;
  identity: string;
  restrictedHash: string;
  walletAddress: string;
  location: ILocation | null;
  device: IDevice | null;
  referrer: IReferrer;
  walletProfile: IWallet | null;
  spindlIdentity: string | null;
  page: IPage;
  screenSize: string | null;
  clientTs: string | null;
  sdkType: string | null;
  sequenceId: number | null;
  customerUserId: string | null;
  isProxyReq: boolean | null;
}

export interface IDefaultEvent {
  id: string;
  event: EventLogName.default;
  name: string;
  eventTypeId: string;
  properties: any;
  timestamp: string;
  identity: string;
  restrictedHash: string;
  walletAddress: string;
  location: ILocation | null;
  device: IDevice;
  referrer: IReferrer;
  walletProfile: IWallet | null;
  spindlIdentity: string | null;
  page: IPage;
  screenSize: string | null;
  clientTs: string | null;
  sdkType: string | null;
  sequenceId: number | null;
  customerUserId: string | null;
  isProxyReq: boolean | null;
}

export interface IPublicConversionLog {
  id: string;
  event: EventLogName.contract_conversion;
  timestamp: string;
  walletAddress: string;
  action: IConversionAction;
  spindlIdentity: string | null;
  walletProfile: IWallet | null;
}

export type TEvent = IPublicClickLog | IPublicMatchLog | IPublicConversionLog | IPublicPageViewLog | ICustomEvent | IDefaultEvent;

export enum SdkType {
  LITE = 'lite',
  FULL = 'full',
}

export type CampaignIndexResponse = {
  campaignCreateds: {
    campaignId: string;
    timestamp_: string;
    paymentType: string;
  }[];
  campaignStatusUpdateds: {
    campaignId: string;
    oldState: string;
    newState: string;
    timestamp_: string;
  }[];
};

export enum EventLogName {
  contract_conversion = 'contract_conversion',
  page_view = 'page_view',
  link_redirect = 'link_redirect',
  wallet_connect = 'wallet_connect',
  custom = 'custom',
  action = 'action',
  third_party = 'third_party',
  default = 'default',
}

export enum EventLogType {
  wallet_connect_events = 'wallet_connect_events',
  page_view_events = 'page_view_events',
  contract_conversions = 'contract_conversions',
  custom_events = 'custom_events',
  default_events = 'default_events',
}

export type Link = {
  createdAt: string;
  id: string;
  isDeleted: boolean;
  lastInvocation: string;
  name: string;
  orgId: string;
  slug: string;
  totalHits: number;
  updatedAt: string;
  url: string;
  hashParams?: boolean;
  customSlug?: string;
};

export type Links = {
  links: Link[];
  customDomains: { domain: string }[];
};

export type LinkStats = {
  stats: Record<string, { pvs: number; sessions: number; visitors: number }>;
};

export type EventOption = {
  eventName: string;
  eventType: UnifiedEventType;
  label: string;
  measures: { id: string; aggType: string; label: string }[];
  filters: DimensionOption[];
};

export type TargetingOption = {
  id: string;
  name: string;
  type: string;
  num_addresses: number;
};

export type PublisherOption = {
  id: string;
  name: string;
  description: string | null;
  slug: string;
};

export type FilterValueOption = {
  label: string;
  value: string;
  occurrences: number;
};

export type DimensionOption = {
  label: string;
  id: string;
  type: DimensionType;
  category?: string;
};

export type EventMeasureOption = { label: string; aggType: string; id: string };

export enum SankeyDirection {
  ENTERING = 'entering',
  LEAVING = 'leaving',
}

export type ExpandedEvent = {
  event: ConfiguredEvent;
  segment: GroupBy | undefined;
};

export enum UnionType {
  AND = 'AND',
  OR = 'OR',
}

export type FormattedChartBuilderEvent = {
  eventType?: UnifiedEventType;
  eventName?: string;
  eventLabel?: string;
  filters?: { id: string; value: string[] }[];
  groupBy?: { id: string; type: DimensionType };
  measure?: { id: string; aggType: string; label: string };
};

/*
  Events ----------------------------
*/

type BaseEvent = {
  id: string;
  eventType?: UnifiedEventType;
  eventName: string;
  label: string;
  filters?: { id: string; value: string[]; op?: FilterOperation; type: DimensionType }[];
};

export type ConfiguredEvent = BaseEvent & {
  measure: EventMeasureOption;
  groupBy?: { id: string; type: DimensionType };
};

export type AudienceEvent = BaseEvent & {
  unionType: UnionType;
  relativeDateRange?: { days: number };
};

export type CampaignEvent = BaseEvent & {
  referrerValue: string;
  referentValue?: string;
  rewardType: {
    label: string;
    value: string;
  };
};

export enum UnifiedEventType {
  WALLET_CONNECT = 'wallet_connect',
  PAGE_VIEW = 'page_view',
  CUSTOM = 'custom',
  CONTRACT_CONVERSION = 'contract_conversion',
  ACTION = 'action',
  THIRD_PARTY = 'third_party',
  DEFAULT = 'default',
}

export enum FilterOperation {
  IN = 'in',
  EQ = 'eq',
  GTE = 'gte',
  GT = 'gt',
  LTE = 'lte',
  LT = 'lt',
}

export enum DimensionType {
  DEFAULT = 'default',
  AUDIENCE = 'audience',
  NUMERIC = 'numeric',
}

// TODO: Add chart builder types to this section
/*
  Chart Builder ----------------------------
*/

export enum ChartBuilderOptions {
  LINE_CHART = 0,
  FUNNELS = 1,
  RETENTION = 2,
  TABLE = 3,
  KPI = 4,
  PATHS = 5,
  PIE_CHART = 6,
}

export enum FunnelChartTabs {
  BAR_CHARTS = 'bar_chart',
  TABLE = 'table',
}

export type BuildFunnelData = {
  name: string;
  totalEventsMeasured: number;
  totalEventsUnbound: number;
  uniqueUsersMeasured: number;
  uniqueUsersUnbound: number;
};

export type FunnelDataItem = { name: string } & { [key: string]: string | number };

export type APIError = {
  status: number;
  message: string;
  error: string;
};

export enum FunnelMeasureType {
  UNIQUE_USERS = 'UNIQUE_USERS',
  TOTAL_EVENTS = 'TOTAL_EVENTS',
}

export type EventDictionaryData = {
  id: string;
  name: string;
  type: 'custom' | 'contract_conversion';
  orgId: string;
  isConversion: boolean;
  displayName: string;
  description: string;
  createdAt: Date;
  revenue: null | number;
  updatedAt: Date;
};

export enum AudienceStatus {
  CREATED = 'created',
  IS_REFRESHING = 'is_refreshing',
  ACTIVE = 'active',
  PAUSED = 'paused',
}

export enum AudienceType {
  SPINDL_ID = 'spindl_id', // spindl customers who have spindl_id as their ID
  WALLET = 'wallet', // spindl customers who have wallet as their ID
  GLOBAL = 'global',
  MANUAL = 'manual', // csv
  LOOKALIKES = 'lookalikes',
}

export enum AudienceRefreshType {
  DYNAMIC = 'dynamic',
  STATIC = 'static',
}

export type AudienceOption = {
  id: string;
  audience_name: string;
  status: AudienceStatus;
  audience_type: AudienceType;
  audience_definition: AudienceEvent[];
  description: string;
  created_at: Date;
  updated_at: Date | null;
  size: number | null;
};

export const FunnelMeasureNames = {
  [FunnelMeasureType.UNIQUE_USERS]: 'Unique Users',
  [FunnelMeasureType.TOTAL_EVENTS]: 'Total Events',
};

export type GroupBy = {
  id: string;
  label?: string;
  type?: DimensionType;
};

export type ChartJsonData = {
  type: ChartBuilderOptions;
  title?: string;
  info?: string;
  startDate?: string;
  endDate?: string;
  interval: string;
  events: ConfiguredEvent[];
  funnelMeasure: FunnelMeasureType;
  globalGroupBy?: GroupBy;
  funnelGroupBy?: GroupBy | string; // Deprecated
  expandedEvents?: ExpandedEvent[];
  direction?: SankeyDirection;
};

/*
  Creative Studio ----------------------------
*/

export enum CreativeStatus {
  IN_REVIEW = 'IN_REVIEW',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PAUSED = 'PAUSED',
}

export type Creative = {
  id: string;
  orgId: string;
  advertiserId: string;
  name: string;
  description: string | null;
  displayTypeId: string;
  displayTypeName: string;
  displayTypeDescription: string | null;
  title: string | null;
  content: string | null;
  image: string | null;
  imageVariants: ImageConfig | null;
  ctaText: string | null;
  category: string | null;
  extraData: Record<string, any> | null;
  status: CreativeStatus;
  link: string | null;
  createdAt: Date;
  updatedAt: Date;
  startDate: string;
  endDate: string;
};

type CreativeFields = {
  required: string[];
  optional: string[];
  fieldConstraints: {
    [key: string]: { [key: string]: any };
  };
};

export type CreditBalanceData = {
  id: string;
  orgId: string;
  walletAddress: string;
  suborgId: string;
  version: string;
  createdAt: string;
  updatedAt: string;
  chainId: number;
  paymentType: string;
  balance: number;
  decimals: number;
  currencySymbol?: string;
  smartAccountAddress?: string;
};

export type TCardUnit = {
  id: string;
  name: string;
  description?: string;
  title: string;
  content: string;
  image: string;
  ctaText: string;
  category: string;
};

export type ImageConfig = {
  '1x': {
    url?: string;
    height?: number;
    width?: number;
    minWidth: number;
    minHeight: number;
    maxWidth: number;
    maxHeight: number;
    minAspectRatio: number;
    maxAspectRatio: number;
  };
  [key: string]: {
    url?: string;
    minWidth?: number;
    minHeight?: number;
    maxWidth?: number;
    maxHeight?: number;
    minAspectRatio?: number;
    maxAspectRatio?: number;
  };
};

export type CreativeOption = {
  id: string;
  name: string;
  description: string | null;
  allowAllImageConfigs: boolean;
  supportedImageConfigs: ImageConfig;
  maxTitleLength: number | null;
  maxContentLength: number | null;
  maxCtaTextLength: number | null;
  fields: CreativeFields | null;
  supportedPublishers: Array<{
    id: string;
    name: string;
    slug: string;
    imageURL: string | null;
  }>;
};

export type CreativeFormData = {
  creativeType: CreativeOption;
  name: string;
  description: string;
  title: string;
  content: string;
  image: {
    data: object;
    name: string;
    link: string;
  };
  ctaText: string;
  category: DropdownOption;
};

export type DropdownOption = {
  value: string;
  label: string;
};
